import * as React from "react";
import Contact from "../components/sections/Contact";
import Layout from "../components/Layout";
import SEO from "../components/Seo";

const ContactPage = () => {
  return (
    <Layout>
      <SEO title={"Contact"} />
      <Contact />
    </Layout>
  );
};

export default ContactPage;
